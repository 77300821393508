import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Spin, Alert } from 'antd';
import { 
  getAccountById, 
  listWirelineResidualRows, 
  listRogersWirelineRecords, 
} from '../../utils/api';
import { 
  AccountData, 
  ResidualRecord, 
  WirelineRecord,
} from '../../types/residualTypes';
import { combineResidualData } from '../../utils/residualUtils';
import { ResidualTable } from './tables/ResidualTable';
import { AccountHeader } from './components/AccountHeader';


interface State {
  accountData: AccountData | null;
  residualData: ResidualRecord[];
  wirelineData: WirelineRecord[];
  loading: boolean;
  error: string | null;
  showUnmerged: boolean;
}

const initialState: State = {
  accountData: null,
  residualData: [],
  wirelineData: [],
  loading: true,
  error: null,
  showUnmerged: false
};

const ResidualDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<State>(initialState);

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        setState(prev => ({ ...prev, error: 'No ID provided', loading: false }));
        return;
      }

      try {
        const [account, residuals, wireline] = await Promise.all([
          getAccountById(id),
          listWirelineResidualRows(id),
          listRogersWirelineRecords(id)
        ]);

        setState(prev => ({
          ...prev,
          accountData: account,
          residualData: residuals,
          wirelineData: wireline,
          loading: false
        }));
      } catch (err) {
        setState(prev => ({
          ...prev,
          error: err instanceof Error ? err.message : 'An error occurred',
          loading: false
        }));
      }
    };

    fetchData();
  }, [id]);



  const handleToggleUnmerged = React.useCallback((checked: boolean) => {
    setState(prev => ({ ...prev, showUnmerged: checked }));
  }, []);

  const combinedData = React.useMemo(() => 
    combineResidualData(state.residualData, state.wirelineData, state.showUnmerged), 
    [state.residualData, state.wirelineData, state.showUnmerged]
  );



  if (state.error) {
    return (
      <div style={{ padding: '24px' }}>
        <Alert type="error" message={state.error} />
      </div>
    );
  }

  if (state.loading || !state.accountData) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ minHeight: '100vh' }}>
      <div style={{ marginBottom: 16 }}>
        <AccountHeader
          accountData={state.accountData}
          accountId={id || ''}
        />
      </div>

      <Typography.Title level={4} style={{ margin: '0 0 24px 0', fontWeight: 600 }}>
        Billing Services
      </Typography.Title>
      <ResidualTable 
        data={combinedData} 
        showUnmerged={state.showUnmerged}
        onToggleUnmerged={handleToggleUnmerged}
      />

    </div>
  );
};

export default ResidualDetailsPage;
