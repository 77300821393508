import React from 'react';
import { AccountData } from '../../../types/residualTypes';

interface AccountHeaderProps {
  accountData: AccountData;
  accountId: string;
}

export const AccountHeader: React.FC<AccountHeaderProps> = ({ accountData, accountId }) => (
  <div style={{ marginBottom: '24px' }}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '16px' }}>
      <h1 style={{ margin: 0 }}>{accountData.name}</h1>
    </div>
  </div>
);
