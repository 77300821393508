import React, { useEffect, useState } from 'react';
import { Layout, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { checkUserAccess, UserAccessLevel } from '../../auth/authService';
import RadixNavMenu from './RadixNavMenu';
import './Header.css';

const { Header } = Layout;

interface AppHeaderProps {
  quoteRequestId?: string;
  newProp?: string;
}

const AppHeader: React.FC<AppHeaderProps> = () => {
  const [userAccess, setUserAccess] = useState<UserAccessLevel>('none');
  useEffect(() => {
    const fetchUserAccess = async () => {
      const access = await checkUserAccess();
      setUserAccess(access);
    };
    fetchUserAccess();
  }, []);

  return (
    <Header className="app-header">
      {/* Menu and Logo section */}
      <div className="header-left">
        <RadixNavMenu userAccess={userAccess} />
        <Link to="/residual-check" style={{ textDecoration: 'none' }}>
          <div className="logo-container">
            <img src="/foxylogo.png" alt="Foxy Logo" className="logo-image" />
            <span className="logo-text">Ledger</span>
          </div>
        </Link>
      </div>

      {/* Avatar section */}
      <div className="header-right">
        <div className="user-avatar">
          <Avatar 
            icon={<UserOutlined />} 
            className="avatar-icon"
          />
        </div>
      </div>
    </Header>
  );
};

export default AppHeader;
