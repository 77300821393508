import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import MaintenancePage from './pages/MaintenancePage';
import { Layout } from 'antd';
import { MsalProvider } from '@azure/msal-react';
import ResidualCheckPage from './pages/ResidualCheck/ResidualCheck';
import ResidualCheckAGGridPage from './pages/ResidualCheck/ResidualCheckAGGridPage';
import ResidualCheckTanstackPage from './pages/ResidualCheck/ResidualCheckTanstackPage';
import BaseScrubAGGridPage from './pages/BaseScrub/BaseScrubAGGridPage';
import Dashboard from './pages/Dashboard/Dashboard';
import ResidualDetailsPage from './pages/ResidualDetails/ResidualDetails';
import BillingProfilePage from './pages/BillingProfile/BillingProfile';
import AccountDetailsPage from './pages/AccountDetails/AccountDetails';
import ResidualBaseUploadPage from './pages/ResidualBaseUpload/ResidualBaseUploadPage';
import HeatMapUploadPage from './pages/HeatMapUpload/HeatMapUploadPage';
import CallidusStatementUploadPage from './pages/CallidusStatementUpload/CallidusStatementUploadPage';
import WonServicesPage from './pages/WonServices/WonServicesPage';
import ProductProfitDashboardPage from './pages/ProductProfitDashboard/ProductProfitDashboardPage';
import MasterResidualPage from './pages/MasterResidual/MasterResidualPage';
import IncomingWirelinePaymentsPage from './pages/IncomingWirelinePayments/IncomingWirelinePaymentsPage';
import RenewalsPage from './pages/Renewals/RenewalsPage';
import RenewalsCheck from './pages/RenewalsCheck/RenewalsCheck';
import VacationTrackerPage from './pages/VacationTracker/VacationTrackerPage';
import TokenPage from './pages/Token/TokenPage';
import AppHeader from './layouts/components/Header';
import { AuthRoute } from './auth/AuthRoute';
import RouteGuard from './auth/RouteGuard';
import { msalInstance, initializeMsal } from './auth/authConfig';
import { Link } from 'react-router-dom';

const { Content } = Layout;

// Domain configuration
const DOMAINS = {
  CPQ: 'cpq.infusion-it.com',
  MAIN: 'foxyledger.infusion-it.com',
};

// Create a wrapper component to handle the conditional header rendering
const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const isQuotePage = location.pathname.startsWith('/quote/');
  const isAGGridPage = location.pathname === '/residual-check-ag' || 
                      location.pathname === '/base-scrub' || 
                      location.pathname === '/dashboard' ||
                      location.pathname.startsWith('/account-details');
  const isRenewalsCheckPage = location.pathname === '/renewal-check';
  const isDarkThemePage = isAGGridPage || isRenewalsCheckPage;

  // Apply dark theme class to body element
  useEffect(() => {
    if (isDarkThemePage) {
      document.body.classList.add('dark-theme-layout');
    } else {
      document.body.classList.remove('dark-theme-layout');
    }
    return () => {
      document.body.classList.remove('dark-theme-layout');
    };
  }, [isDarkThemePage]);

  return (
    <Layout 
      className={isDarkThemePage ? 'dark-theme-layout' : ''} 
      style={{ background: isDarkThemePage ? '#141414' : undefined }}
    >
      {!isQuotePage && <AppHeader />}
      {isDarkThemePage ? (
        children
      ) : (
        <Content style={{ padding: isQuotePage ? 0 : '0 50px', marginTop: isQuotePage ? 0 : '20px' }}>
          {children}
        </Content>
      )}
    </Layout>
  );
};

// Domain redirect handler component
const DomainHandler = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const hostname = window.location.hostname;
  const isCPQDomain = hostname === DOMAINS.CPQ;
  const isMainDomain = hostname === DOMAINS.MAIN;
  const isCPQRoute = location.pathname.match(/^\/(quote|quotes)/);

  useEffect(() => {
    // If on CPQ domain but not on a CPQ route, redirect to main domain
    if (isCPQDomain && !isCPQRoute) {
      window.location.href = `https://${DOMAINS.MAIN}${location.pathname}${location.search}`;
    }
    // If on main domain and accessing a CPQ route, redirect to CPQ domain
    else if (isMainDomain && isCPQRoute) {
      window.location.href = `https://${DOMAINS.CPQ}${location.pathname}${location.search}`;
    }
  }, [location.pathname, location.search, isCPQDomain, isMainDomain, isCPQRoute]);

  return <>{children}</>;
};

// Add this new component
const NotFound = () => {
  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center', 
      justifyContent: 'center',
      minHeight: 'calc(100vh - 64px)',
      padding: '20px',
      textAlign: 'center'
    }}>
      <img src="/foxylogo.png" alt="Foxy Logo" style={{ height: '60px', marginBottom: '24px' }} />
      <h1 style={{ fontSize: '24px', marginBottom: '16px' }}>404 - Page Not Found</h1>
      <p style={{ marginBottom: '24px', color: '#595959' }}>
        The page you're looking for doesn't exist or has been moved.
      </p>
      <Link to="/" style={{ 
        padding: '8px 16px',
        background: '#1890ff',
        color: 'white',
        borderRadius: '4px',
        textDecoration: 'none'
      }}>
        Return Home
      </Link>
    </div>
  );
};

// Wrapper component for routes that need the AppLayout
const LayoutWrapper = ({ element }: { element: React.ReactNode }) => {
  return <AppLayout>{element}</AppLayout>;
};

function App() {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    initializeMsal().then(() => {
      setIsInitialized(true);
    });
  }, []);

  if (!isInitialized) {
    return <div>Initializing authentication...</div>;
  }

  return (
    <MsalProvider instance={msalInstance}>
      <Router>
        <AuthRoute>
          <DomainHandler>
            <Routes>
              {/* Admin-only routes */}
              <Route 
                path="/product-compensation" 
                element={
                  <RouteGuard requiredAccess="admin">
                    <ProductProfitDashboardPage />
                  </RouteGuard>
                } 
              />
              <Route 
                path="/incoming-wireline-payments" 
                element={
                  <RouteGuard requiredAccess="admin">
                    <LayoutWrapper element={<IncomingWirelinePaymentsPage />} />
                  </RouteGuard>
                } 
              />
              <Route 
                path="/vacation-tracker" 
                element={
                  <RouteGuard requiredAccess="admin">
                    <LayoutWrapper element={<VacationTrackerPage />} />
                  </RouteGuard>
                } 
              />
              <Route 
                path="/token" 
                element={
                  <RouteGuard requiredAccess="admin">
                    <LayoutWrapper element={<TokenPage />} />
                  </RouteGuard>
                } 
              />
              
              {/* Routes with AppLayout */}
              <Route 
                path="/dashboard" 
                element={<LayoutWrapper element={<Dashboard />} />} 
              />
              <Route 
                path="/residual-check" 
                element={<LayoutWrapper element={<ResidualCheckPage />} />} 
              />
              {/* AG Grid version */}
              <Route 
                path="/residual-check-ag" 
                element={<LayoutWrapper element={<ResidualCheckAGGridPage />} />} 
              />
              {/* Base Scrub */}
              <Route 
                path="/base-scrub" 
                element={<LayoutWrapper element={<BaseScrubAGGridPage />} />} 
              />
              {/* Tanstack Table version */}
              <Route 
                path="/residual-check-tanstack" 
                element={<LayoutWrapper element={<ResidualCheckTanstackPage />} />} 
              />
              {/* Renewals Check */}
              <Route 
                path="/renewal-check" 
                element={<LayoutWrapper element={<RenewalsCheck />} />} 
              />
              <Route 
                path="/residual-details/:id" 
                element={<LayoutWrapper element={<ResidualDetailsPage />} />} 
              />
              <Route 
                path="/BillingProfile/:id" 
                element={<LayoutWrapper element={<BillingProfilePage />} />} 
              />
              <Route 
                path="/account-details/:id" 
                element={<LayoutWrapper element={<AccountDetailsPage />} />} 
              />
              <Route 
                path="/residual-upload" 
                element={<LayoutWrapper element={<ResidualBaseUploadPage />} />} 
              />
              <Route 
                path="/wireline-upload" 
                element={<LayoutWrapper element={<HeatMapUploadPage />} />} 
              />
              <Route 
                path="/raw-excel-upload" 
                element={<LayoutWrapper element={<CallidusStatementUploadPage />} />} 
              />
              <Route 
                path="/won-services" 
                element={<LayoutWrapper element={<WonServicesPage />} />} 
              />
              <Route 
                path="/master-residual-list" 
                element={<LayoutWrapper element={<MasterResidualPage />} />} 
              />
              {/* Add new renewals route */}
              <Route 
                path="/renewals" 
                element={<LayoutWrapper element={<RenewalsPage />} />} 
              />
              {/* Keep the root route and catch-all route at the end */}
              <Route 
                path="/" 
                element={<LayoutWrapper element={<ResidualCheckPage />} />} 
              />
              <Route path="*" element={<LayoutWrapper element={<NotFound />} />} />
              <Route 
                path="/maintenance" 
                element={<LayoutWrapper element={<MaintenancePage />} />} 
              />
            </Routes>
          </DomainHandler>
        </AuthRoute>
      </Router>
    </MsalProvider>
  );
}

export default App;
