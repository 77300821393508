import React from 'react';

const MaintenancePage: React.FC = () => {
  return (
    <div style={{ 
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f0f0f0'
    }}>
      <div style={{ 
        textAlign: 'center',
        padding: '2rem',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
      }}>
        <h1>Maintenance Mode</h1>
        <p>We're currently performing maintenance. Please check back soon.</p>
      </div>
    </div>
  );
};

export default MaintenancePage;
